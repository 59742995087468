
#slider_main_page{
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
    font-family: 'Poppins';
}
.row{
    width: 100%;
    max-width: 1170px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
    padding: 50px 0;
    flex-wrap:unset !important;
}

.row .cardd{
    width: 30%;
    padding: 25px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    flex-direction: column;
    color: #000;
    position: relative;
}

.row .cardd .top{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.row .cardd .top img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
}
.row .cardd:hover .top img{
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
}

.cardd h3{
    font-weight: 500;
    font-size: 23px;
    line-height: 32px;
    padding-bottom: 15px;
}
.cardd p{
    font-weight: 200;
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 20px;
}
.cardd a.btn{
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    color: #212121;
    background: #fff;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 2px;
    padding: 10px 25px;
    user-select: none;
    border: 2px solid #daa520;
}
.cardd a.btn i{
    margin-left: 5px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.cardd a.btn:hover i{
    margin-left: 20px;
}

.cardd:nth-child(2){
    flex-direction: column-reverse;
}
.cardd::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #333;
    opacity: 0.5;
    z-index: -1;
    clip-path: circle(50% at 0 100%);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.cardd:hover::before{
    clip-path: circle(150% at 0 100%);
    background: #daa520;
    opacity: 1;
}


/* Responsive ======================== */

@media(max-width: 1280px){
    .row{
        grid-template-columns: 1fr;
    }
    .row .cardd{
        align-items: center;
        flex-direction: row;
    }
    .cardd:nth-child(2){
        flex-direction: row-reverse;
    }
    .row .cardd .top{
        height: 100%;
    }
    .row .cardd{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .row{
      flex-wrap:wrap !important;
    }
}
@media(max-width: 768px){
    .row .cardd{
        flex-direction: column;
    }
    .cardd:nth-child(2){
        flex-direction: column;
    }
}
@media(min-width: 1500px){
  .row{
    max-width: 1500px;
  }
}
